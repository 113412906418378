import { useEffect } from "react";

import { Center, Loader } from "@mantine/core";

import { getEmptyLayout } from "components/layouts/empty";

export default function CMAppTree(): JSX.Element {
  useEffect(() => {
    (async () => {
      await import("lib/frontend/cm/index");
    })();
  }, []);

  return (
    <div suppressHydrationWarning>
      <Center style={{ height: "100vh" }}>
        <Loader />
      </Center>
    </div>
  );
}

CMAppTree.getLayout = getEmptyLayout;
